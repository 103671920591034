import React from "react"
import { ArrowRightOutlined, CloseOutlined } from "@ant-design/icons"
import { Button, Modal, Typography } from "antd"
import { graphql, Link, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import TextLoop from "react-text-loop"
import style from "./PromotionBar.module.scss"

const isExpired = (startDate, endDate) => {
  const now = Date.now()
  return !(Date.parse(startDate) < now && Date.parse(endDate) > now)
}

class PromotionBar extends React.Component {
  constructor(props) {
    super(props)
    const { promo, news } = props
    const link = [] // add any evergreen content here

    const events = [
      ...promo.filter(p => !isExpired(p.startDate, p.endDate)),
      ...news.filter(n => !isExpired(n.startDate, n.endDate)),
      ...link,
    ]

    this.state = {
      events,
      interval: 4000,
      modal: promo[0] || news[0] || link[0] || {},
      showBar: !!events.length,
      vis: false,
    }
  }

  openModal = content => {
    this.setState({
      modal: content,
      vis: !this.state.vis,
    })
  }

  closeModal = () => {
    this.setState({ vis: false })
  }

  getEventType = event => {
    if (event.hasOwnProperty(`promoCode`)) return `promo`
    if (event.hasOwnProperty(`blogPost`)) return `blog`
    return `link`
  }

  render() {
    const { events, interval, modal, showBar, vis } = this.state

    if (!showBar) return null

    const eventType = this.getEventType(modal)

    const Button1 = () => (
      <Button
        className={style.cancel}
        onClick={this.closeModal}
        size="large"
        type="ghost"
      >
        {eventType === `promo` ? `No, Thanks!` : `Close`}
      </Button>
    )

    const Button2 = () => (
      <Button className={style.book} onClick={this.closeModal} size="large">
        {eventType === `promo` ? (
          <a
            href={`${process.env.GATSBY_BOOKING_ENGINE}?access_code=${modal.promoCode}`}
          >
            Book Now!
          </a>
        ) : (
          <Link
            to={
              eventType === `blog`
                ? `/${(modal.blogPost.slug + `/`).replace(`//`, `/`)}`
                : modal.slug
            }
          >
            Read More
          </Link>
        )}
      </Button>
    )

    return (
      <div className={style.wrapper}>
        <TextLoop interval={interval} mask>
          {events.length &&
            events.map((event, index) => {
              if (this.getEventType(event) === `promo`) {
                return (
                  <Button
                    key={index}
                    onClick={() => this.openModal(event)}
                    type="link"
                  >
                    <div style={{ width: `100vw` }}>
                      <span className={style.title}>{event.title}</span>{" "}
                      <span className={style.link}>
                        View Promo <ArrowRightOutlined />
                      </span>
                    </div>
                  </Button>
                )
              } else {
                return (
                  <Button
                    key={index}
                    onClick={() => this.openModal(event)}
                    type="link"
                  >
                    <div style={{ width: `100vw` }}>
                      <span className={style.title}>{event.title}</span>{" "}
                      <span className={style.link}>
                        Read More <ArrowRightOutlined />
                      </span>
                    </div>
                  </Button>
                )
              }
            })}
        </TextLoop>
        <Modal
          bodyStyle={{ padding: 0 }}
          centered
          className={style.modal}
          closeIcon={<CloseOutlined className={style.closeIcon} />}
          footer={null}
          onCancel={this.closeModal}
          visible={vis}
        >
          <Img
            alt={modal.featuredImage.description}
            className={style.image}
            fluid={modal.featuredImage.fluid}
          />
          <div className={style.content}>
            <Typography.Title className={style.modalTitle} level={4}>
              {modal.title}
            </Typography.Title>
            <Typography.Paragraph
              className={style.details}
              ellipsis={{ expandable: false, rows: 6 }}
            >
              {eventType === `link` ? (
                modal.details
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: modal.details.childMarkdownRemark.html,
                  }}
                />
              )}
            </Typography.Paragraph>
            {eventType === `promo` ? (
              <Typography.Paragraph className={style.promo}>
                <span className={style.label}>PROMO CODE:</span>
                <br />
                <span className={style.code}>{modal.promoCode}</span>
              </Typography.Paragraph>
            ) : null}
            <div className={style.buttonWrapper}>
              <Button1 />
              <Button2 />
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        news: allContentfulAnnouncement(
          filter: {
            business: { name: { eq: "Residences at Biltmore" } }
            published: { eq: true }
          }
          sort: { fields: createdAt, order: DESC }
        ) {
          nodes {
            blogPost {
              slug
            }
            details {
              childMarkdownRemark {
                html
              }
            }
            endDate
            featuredImage {
              description
              fluid(maxHeight: 300) {
                ...fluid_withWebp_tracedSVG
              }
            }
            startDate
            title
          }
        }
        promo: allContentfulPromotion(
          filter: {
            business: { name: { eq: "Residences at Biltmore" } }
            published: { eq: true }
          }
          sort: { fields: createdAt, order: DESC }
        ) {
          nodes {
            details {
              childMarkdownRemark {
                html
              }
            }
            endDate
            featuredImage {
              description
              fluid(maxHeight: 300) {
                ...fluid_withWebp_tracedSVG
              }
            }
            promoCode
            startDate
            title
          }
        }
        links: contentfulAsset(
          contentful_id: { eq: "19Z2wuN8Nb0EDtHzFieTqO" }
        ) {
          description
          fluid(maxWidth: 500) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    `}
    render={data => {
      const links = data.links
      const news = data.news.nodes
      const promo = data.promo.nodes

      return <PromotionBar links={links} news={news} promo={promo} />
    }}
  />
)
