import React from "react"
import { Button, Col, Dropdown, Icon, Menu, Row } from "antd"
import { CaretDownOutlined } from "@ant-design/icons"
import { Link, StaticQuery, graphql } from "gatsby"
import { Location } from "@reach/router"
import style from "./NavMenu.module.scss"
import { MenuOutlined } from "@ant-design/icons"

const DropdownMenu = ({
  active,
  currentPathname,
  items,
  label = null,
  node,
}) => {
  const [open, setOpen] = React.useState(false)

  return (
    <Dropdown
      arrow
      className={style.dropdown}
      placement="bottomCenter"
      onVisibleChange={visible => setOpen(visible)}
      trigger="click"
      overlay={
        <Menu className={style.dropdown} selectedKeys={[currentPathname]}>
          {items.map(child => (
            <Menu.Item className={style.dropdownItem} key={child.href}>
              <Link to={child.href}>{child.label}</Link>
            </Menu.Item>
          ))}
        </Menu>
      }
    >
      <Button
        block
        className={style.navItem}
        data-active={active}
        data-open={open}
        type={"text"}
      >
        {label || node.label}
      </Button>
    </Dropdown>
  )
}

class NavMenu extends React.Component {
  state = {
    currentSubMenu: "",
  }

  openSubMenu = label => {
    if (this.state.currentSubMenu !== label) {
      this.setState({
        currentSubMenu: label,
      })
    }
  }

  closeSubMenu = label => {
    if (this.state.currentSubMenu === label) {
      this.setState({
        currentSubMenu: "",
      })
    }
  }

  render() {
    const { logo, nodes, bookingEngine } = this.props
    const mainLinks = nodes.filter(
      ({ childOf, label }) => childOf !== `More...` && label !== `More...`
    )
    const moreLinks = nodes.filter(
      ({ childOf, label }) => childOf === `More...` && label !== `More...`
    )

    return (
      <Location>
        {({ location }) => (
          <Row
            align="middle"
            className={style.wrapper}
            justify="center"
            type="flex"
          >
            <Col key="logo" className={style.logo}>
              <Link to="/">
                <img
                  src={logo.file.url}
                  alt={logo.description}
                  className="nav-logo"
                  height={50}
                />
              </Link>
            </Col>
            {mainLinks.map((node, index) => {
              if (node.hideOn.includes(location.pathname)) {
                return false
              }
              if (
                mainLinks.find(
                  n => n.label !== node.label && n.childOf === node.label
                )
              ) {
                const items = mainLinks.filter(
                  ({ childOf, label }) =>
                    childOf === node.label && label !== node.label
                )
                const isActive = !!items.find(
                  ({ href }) => href === location.pathname
                )

                return (
                  <Col key={index}>
                    <DropdownMenu
                      active={isActive}
                      currentPathname={location.pathname}
                      currentSubMenu={this.state.currentSubMenu}
                      items={items}
                      label={
                        <span>
                          {node.label} <CaretDownOutlined />
                        </span>
                      }
                      node={node}
                    />
                  </Col>
                )
                // if this node had any children, it wouldn't have made it to this point.
                // however, it may still be a node that belongs in a submenu, so make sure
                // that it lists itself as its own parent before adding it the main menu.
              } else if (node.label === node.childOf) {
                const isActive = location.pathname === node.href
                return (
                  <Col key={node.href}>
                    <Button
                      block
                      className={style.navItem}
                      data-active={isActive}
                      type="text"
                    >
                      <Link to={node.href}>
                        {node.icon ? <Icon type={node.icon} /> : ""}
                        {node.label}
                      </Link>
                    </Button>
                  </Col>
                )
              }
              // return something here to appease eslint's arrow-function-
              // must-return-something rule
              return true
            })}
            <Col className={style.moreDropdown} style={{ padding: 0 }}>
              <DropdownMenu
                active={
                  !!moreLinks.find(({ href }) => href === location.pathname)
                }
                currentPathname={location.pathname}
                currentSubMenu={this.state.currentSubMenu}
                label={<MenuOutlined />}
                items={moreLinks}
              />
            </Col>
            <Col className={style.ctaItem}>
              <a href={bookingEngine}>
                <Button className={style.cta} size="large" type="ghost">
                  Book Now
                </Button>
              </a>
            </Col>
          </Row>
        )}
      </Location>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        ...NavMenu
        hotel: contentfulHotel(name: { eq: "Residences at Biltmore" }) {
          ...HotelLogoSVG
        }
      }
    `}
    render={data => (
      // import TopNav, MobileNav, SideNav, etc, and then render
      // whichever one is appropriate for the media here:
      <div id="reg-header">
        <NavMenu
          bookingEngine={process.env.GATSBY_BOOKING_ENGINE}
          logo={data.hotel.logo}
          navStyle={props.navStyle}
          navTheme={props.navTheme}
          nodes={data.navMenu.childrenNavMenuJson}
        />
      </div>
    )}
  />
)
