import React from "react"
import { Icon as LegacyIcon } from "@ant-design/compatible"
import { MenuOutlined } from "@ant-design/icons"
import { Button, Col, Drawer, Menu, Row } from "antd"
import { Link, StaticQuery, graphql } from "gatsby"
import { Location } from "@reach/router"
import MediaContext from "@avldev/gatsby-theme-core-ui/src/components/MediaContext"
import style from "./MobileNav.module.scss"

class MobileNavMenu extends React.Component {
  static contextType = MediaContext

  state = {
    currentSubMenu: "",
    mainVisible: false,
    subVisible: false,
  }

  showDrawer = drawer => {
    const key = drawer === `main` ? `mainVisible` : `subVisible`
    this.setState({ [key]: true })
  }

  closeDrawer = drawer => {
    const key = drawer === `main` ? `mainVisible` : `subVisible`
    this.setState({ [key]: false })
  }

  openSubMenu = label => {
    if (this.state.currentSubMenu !== label) {
      this.setState({
        currentSubMenu: label,
      })
    }
  }

  closeSubMenu = label => {
    if (this.state.currentSubMenu === label) {
      this.setState({
        currentSubMenu: "",
      })
    }
  }

  render() {
    const { logo, nodes, bookingEngine } = this.props
    const { width } = this.context

    const menuWidth = width < 600 ? `100vw` : `50vw`
    const subMenuWidth = width < 600 ? `90vw` : `45vw`

    const logoProps = {
      className: style.navLogo,
      height: 50,
      src: logo.file.url,
      style: {
        filter: `contrast(0) brightness(2)`,
      },
    }

    const buttonProps = {
      icon: <MenuOutlined style={{ fontSize: 24 }} />,
      type: "link",
      onClick: () => this.showDrawer(`main`),
      style: {
        color: `white`,
        marginLeft: 15,
        top: 2,
      },
    }

    const menuTitle = (
      <img
        alt={logo.description}
        className={style.menuLogo}
        src={logo.file.url}
      />
    )

    return (
      <Location>
        {({ location }) => (
          <>
            <Row
              id="nav-menu-mobile"
              align="middle"
              justify="space-between"
              type="flex"
              style={{ padding: `0 50px` }}
              className={style.wrapper}
            >
              <Col span={12}>
                <Link to="/">
                  <img {...logoProps} alt={logo.description} />
                </Link>
              </Col>
              <Col span={1}>
                <Button {...buttonProps} />
              </Col>
            </Row>
            <Drawer
              title={menuTitle}
              placement="right"
              closable={true}
              onClose={() => this.closeDrawer(`main`)}
              visible={this.state.mainVisible}
              width={menuWidth}
            >
              <Menu
                className={style.mobileNav}
                selectedKeys={[location.pathname]}
                mode="inline"
                style={{
                  borderRight: 0,
                  fontWeight: 700,
                  textTransform: `uppercase`,
                  width: `100%`,
                }}
              >
                {nodes
                  .filter(node => node.childOf !== `More...`)
                  .map(node => {
                    if (node.hideOn.includes(location.pathname)) {
                      return false
                    }
                    if (
                      nodes.find(
                        n => n.label !== node.label && n.childOf === node.label
                      )
                    ) {
                      const children = nodes.filter(
                        item =>
                          item.label !== node.label &&
                          item.childOf === node.label
                      )
                      const active = !!children.find(
                        child => child.href === location.pathname
                      )
                      return (
                        <Menu.SubMenu
                          className={style.navItem}
                          data-active={active}
                          key={node.label}
                          title={
                            <span>
                              {node.icon ? (
                                <>
                                  <LegacyIcon type={node.icon} /> {node.label}
                                </>
                              ) : (
                                <>{node.label}</>
                              )}
                            </span>
                          }
                          onTitleClick={() => this.openSubMenu(node.label)}
                          onMouseEnter={() => this.openSubMenu(node.label)}
                          onMouseLeave={() => this.closeSubMenu(node.label)}
                          children={nodes
                            .filter(
                              item =>
                                item.label !== node.label &&
                                item.childOf === node.label
                            )
                            .map(child => (
                              <Menu.Item
                                className={style.navSubItem}
                                key={child.href}
                              >
                                <Link to={child.href}>
                                  {child.icon ? (
                                    <LegacyIcon type={child.icon} />
                                  ) : (
                                    ""
                                  )}
                                  {child.label}
                                </Link>
                              </Menu.Item>
                            ))}
                        />
                      )
                    } else if (node.label === node.childOf) {
                      return (
                        <Menu.Item className={style.navItem} key={node.href}>
                          <Link to={node.href}>
                            {node.icon ? <LegacyIcon type={node.icon} /> : ""}
                            {node.label}
                          </Link>
                        </Menu.Item>
                      )
                    }
                    return true
                  })}
                <Menu.Item
                  className={style.navItem}
                  onClick={() => this.showDrawer(`sub`)}
                >
                  <Link>More...</Link>
                </Menu.Item>
                <Menu.Item className={style.navItem}>
                  <a href={bookingEngine}>
                    <Button
                      block
                      className={style.cta}
                      style={{ height: 40 }}
                      type="ghost"
                    >
                      Book Now
                    </Button>
                  </a>
                </Menu.Item>
              </Menu>
              <Drawer
                placement="right"
                closable={true}
                onClose={() => this.closeDrawer(`sub`)}
                title={menuTitle}
                visible={this.state.subVisible}
                width={subMenuWidth}
              >
                <Menu
                  className={style.mobileSubNav}
                  selectedKeys={[location.pathname]}
                  mode="inline"
                  style={{
                    borderRight: 0,
                    fontWeight: 700,
                    marginTop: 32,
                    textTransform: `uppercase`,
                    width: `100%`,
                  }}
                >
                  {nodes
                    .filter(
                      ({ childOf, label }) =>
                        childOf === `More...` && label !== `More...`
                    )
                    .map(node => (
                      <Menu.Item className={style.navItem} key={node.href}>
                        <Link to={node.href}>{node.label}</Link>
                      </Menu.Item>
                    ))}
                </Menu>
              </Drawer>
            </Drawer>
          </>
        )}
      </Location>
    )
  }
}

// export default MobileNavMenu

export default props => (
  <StaticQuery
    query={graphql`
      {
        ...NavMenu
        hotel: contentfulHotel(name: { eq: "Residences at Biltmore" }) {
          ...HotelLogoSVG
        }
      }
    `}
    render={data => (
      // import TopNav, MobileNav, SideNav, etc, and then render
      // whichever one is appropriate for the media here:
      <div id="mobile-header">
        <MobileNavMenu
          bookingEngine={process.env.GATSBY_BOOKING_ENGINE}
          logo={data.hotel.logo}
          navStyle={props.navStyle}
          navTheme={props.navTheme}
          nodes={data.navMenu.childrenNavMenuJson}
        />
      </div>
    )}
  />
)
