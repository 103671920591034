import React from "react"
import {
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
} from "@ant-design/icons"
import { Col, Typography } from "antd"
import style from "./Footer.module.scss"

const { Title } = Typography

const icon = {
  style: {
    fontSize: 26,
    paddingLeft: 6,
    paddingRight: 6,
  },
}

export default ({ facebook, instagram, twitter }) => {
  return (
    <Col
      className={style.footerColumn}
      xs={{ span: 24, order: 2 }}
      sm={6}
      md={8}
      lg={8}
    >
      <Title level={4}>Follow Us</Title>
      {facebook !== null ? (
        <a key="fb" href={`https://www.facebook.com/${facebook}`}>
          <FacebookOutlined {...icon} />
        </a>
      ) : (
        <></>
      )}
      {twitter !== null ? (
        <a key="tw" href={`https://www.twitter.com/${twitter}`}>
          <TwitterOutlined {...icon} />
        </a>
      ) : (
        <></>
      )}
      {instagram !== null ? (
        <a key="ig" href={`https://www.instagram.com/${instagram}`}>
          <InstagramOutlined {...icon} />
        </a>
      ) : (
        <></>
      )}
    </Col>
  )
}
