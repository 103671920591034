import React from "react"
import { Icon as LegacyIcon } from "@ant-design/compatible"
import { Button, Col, Typography } from "antd"
import { Link } from "gatsby"

import style from "./Footer.module.scss"

const { Title } = Typography

const handleClick = () => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: "generic_event",
      event_category: "Reservations",
      event_action: "Book Online",
      event_label: "Footer CTA",
    })
  }
}

export default props => {
  const buttonProps = {
    className: style.bookButton,
    onClick: handleClick,
    type: "primary",
    size: "large",
    style: props.style || {},
  }

  const iconProps = {
    type: props.buttonIcon,
    style: {
      fontSize: 18,
    },
  }

  const button = url => {
    if (url.startsWith("/")) {
      return (
        <Link to={url}>
          <Button {...buttonProps}>
            <span>
              <LegacyIcon {...iconProps} />
              {props.buttonText}
            </span>
          </Button>
        </Link>
      )
    } else {
      return (
        <Button href={url} {...buttonProps}>
          <LegacyIcon {...iconProps} />
          {props.buttonText}
        </Button>
      )
    }
  }

  return (
    <Col
      className={style.footerColumn}
      xs={{ span: 24, order: 1 }}
      sm={{ span: 10, order: 3 }}
      md={8}
      lg={8}
    >
      <Title level={4}>Next Steps</Title>
      {button(props.link)}
    </Col>
  )
}
