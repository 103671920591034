import React from "react"
import { ArrowUpOutlined } from "@ant-design/icons"
import { BackTop, Layout } from "antd"
import { useStaticQuery, graphql } from "gatsby"
import style from "./Shell.module.scss"
import "../../styles/globals.scss"

import PromotionBar from "../PromotionBar/PromotionBar"
import TopNav from "../NavMenu/TopNav"
import NavMenuMobile from "../NavMenu/MobileNav"
import Footer from "../Footer/Footer"

export default props => {
  // query global content for Shell components from
  // data/globalShellContent.json
  const { hotel } = useStaticQuery(graphql`
    query {
      hotel: contentfulHotel(name: { eq: "Residences at Biltmore" }) {
        name
        ...HotelContact
        ...HotelSocialMedia
        bookingEngineUrl
      }
    }
  `)

  // refer to props for shell component content first, but
  // fall back to the contents of globalShellContent.json
  // if content isn't provided. this allows shell component
  // content to be specified at the page level.
  const page = {
    footer: props.footerContent || {
      contactInfo: {
        phone: hotel.phone,
        email: hotel.email,
        streetAddress: hotel.streetAddress,
        cityStateZip: hotel.cityStateZip,
      },
      socialPlatforms: {
        facebook: hotel.facebookHandle,
        instagram: hotel.instagramHandle,
        twitter: hotel.twitterHandle,
      },
      cta: {
        link: hotel.bookingEngineUrl,
        buttonIcon: `calendar`,
        buttonText: `Book Now!`,
      },
    },
  }

  const navProps = {
    navStyle: props.navStyle,
    navTheme: props.navTheme,
  }

  const backToTop = props.backToTop || 1500

  const backTopProps = {
    className: style.backTopWrapper,
    visibilityHeight: backToTop,
  }

  return (
    <>
      <Layout className={style.layout}>
        <PromotionBar />
        <Layout.Header className={style.header} style={props.headerStyle}>
          <TopNav {...navProps} />
          <NavMenuMobile {...navProps} />
        </Layout.Header>
        <Layout.Content className={style.content}>
          {backToTop ? (
            <BackTop {...backTopProps}>
              <div className={style.backTop}>
                <ArrowUpOutlined />
              </div>
            </BackTop>
          ) : (
            <></>
          )}
          {props.children}
        </Layout.Content>
        <Layout.Footer className={style.footer}>
          <Footer {...page.footer} />
        </Layout.Footer>
      </Layout>
    </>
  )
}
