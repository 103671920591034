/**
Phun With Phone Numbers!
- To strip all characters from a phone number: clickablePhoneNumber()
- To standardize the appearnce of phone numbers: prettifyPhoneNumber()
**/

// removes ' ', '.', '-', '(', ')' from phone numbers
export const clickablePhoneNumber = number =>
  typeof number === "number"
    ? number
    : number
        .replace(/-/g, "")
        .replace(/\(/g, "")
        .replace(/\)/g, "")
        .replace(/\./g, "")
        .replace(/\s/g, "")

// formats number as '(***) ***-****'
export const prettifyPhoneNumber = number => {
  let pretty = "("
  const ugly = String(number).startsWith("1-")
    ? String(clickablePhoneNumber(number.substring(2)))
    : String(clickablePhoneNumber(number))
  for (let index in ugly) {
    pretty += ugly[index]
    if (parseInt(index) === 2) {
      pretty += ") "
    } else if (parseInt(index) === 5) {
      pretty += "-"
    }
  }
  return pretty
}
