import React from "react"
import { Col, Typography } from "antd"
import style from "./Footer.module.scss"

import {
  clickablePhoneNumber,
  prettifyPhoneNumber,
} from "../../utils/phoneNumbers"

const { Title, Text } = Typography

export default ({ phone, streetAddress, cityStateZip }) => {
  return (
    <Col
      className={style.footerColumn}
      xs={{ span: 24, order: 3 }}
      sm={{ span: 8, order: 1 }}
      lg={8}
    >
      <Title level={4}>Contact Us</Title>
      <Text>
        <a
          className="click-to-call"
          href={`tel:${clickablePhoneNumber(phone)}`}
          id={style.phone}
        >
          {prettifyPhoneNumber(phone)}
        </a>
      </Text>
      <br />
      <Text>{streetAddress}</Text>
      <br />
      <Text>{cityStateZip}</Text>
      <br />
      <Text>
        <a className={style.login} href={process.env.GATSBY_OWNER_LOGIN}>
          Owner Login
        </a>
      </Text>
    </Col>
  )
}
