import React from "react"
import { Col } from "antd"
import { useStaticQuery, graphql } from "gatsby"
import style from "./Footer.module.scss"

export default () => {
  const { ctf } = useStaticQuery(graphql`
    query {
      ctf: contentfulHotel(name: { eq: "Residences at Biltmore" }) {
        ...HotelIconSVG
      }
    }
  `)

  const colProps = {
    className: style.topColumn,
    span: 24,
  }

  const { description, file } = ctf.icon

  return (
    <Col {...colProps}>
      <img alt={description} src={file.url} />
    </Col>
  )
}
