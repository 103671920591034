import React from "react"
import { Row } from "antd"
import PropTypes from "prop-types"
import style from "./Footer.module.scss"

import TopColumn from "./TopColumn"
import LeftColumn from "./LeftColumn"
import MiddleColumn from "./MiddleColumn"
import RightColumn from "./RightColumn"
import CopyrightColumn from "./CopyrightColumn"

const Footer = ({ contactInfo, socialPlatforms, cta }) => {
  return (
    <>
      <Row type="flex" justify="center" align="middle" className={style.topRow}>
        <TopColumn />
      </Row>
      <Row
        type="flex"
        justify="center"
        align="top"
        className={style.footerWrapper}
      >
        <LeftColumn {...contactInfo} />
        <MiddleColumn {...socialPlatforms} />
        <RightColumn {...cta} />
      </Row>
      <Row
        type="flex"
        className={style.copyrightRow}
        style={{ height: `calc(48 + 8n)` }}
      >
        <CopyrightColumn />
      </Row>
    </>
  )
}

Footer.propTypes = {
  props: PropTypes.shape({
    contactInfo: PropTypes.shape({
      phone: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      email: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      streetAddress: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      cityStateZip: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    }),
    socialPlatforms: PropTypes.shape({
      facebook: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      twitter: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      instagram: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    }),
    cta: PropTypes.shape({
      link: PropTypes.string.isRequired,
      buttonIcon: PropTypes.string.isRequired,
      buttonText: PropTypes.string.isRequired,
    }),
  }),
}

Footer.defaultProps = {
  props: {
    contactInfo: {
      phone: false,
      email: false,
      streetAddress: false,
      cityStateZip: false,
    },
    socialPlatforms: {
      facebook: false,
      twitter: false,
      instagram: false,
    },
  },
}

export default Footer
