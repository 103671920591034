import React from "react"
import { Col, Typography } from "antd"
import style from "./Footer.module.scss"

export default () => (
  <Col className={style.copyrightColumn} span={24}>
    <Typography.Text className={style.copyrightText}>
      &copy; {new Date().getFullYear()} The Residences at Biltmore. All Rights
      Reserved.
    </Typography.Text>
  </Col>
)
